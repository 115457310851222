import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../../styles/components/UploadMedia.css';

const FOLDER_PATHS = {
    AUDIO: 'audio/128kbps',
    VIDEO: 'video'
};

const SORT_OPTIONS = {
    NEWEST: 'newest',
    OLDEST: 'oldest',
    A_Z: 'a-z',
    Z_A: 'z-a'
};

export default function UploadMedia() {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [mediaList, setMediaList] = useState({
        audio: [],
        video: []
    });
    const [filteredMediaList, setFilteredMediaList] = useState({
        audio: [],
        video: []
    });
    const [audioSortOption, setAudioSortOption] = useState(SORT_OPTIONS.NEWEST);
    const [videoSortOption, setVideoSortOption] = useState(SORT_OPTIONS.NEWEST);
    const [audioSearchTerm, setAudioSearchTerm] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [audioOpen, setAudioOpen] = useState(true);
    const [videoOpen, setVideoOpen] = useState(true);

    useEffect(() => {
        fetchMediaList();
    }, []);

    useEffect(() => {
        // Apply sorting and filtering to audio files
        if (mediaList.audio.length > 0) {
            let filteredAudio = [...mediaList.audio];
            
            // Apply search filter if there's a search term
            if (audioSearchTerm) {
                filteredAudio = filteredAudio.filter(media => 
                    media.name.toLowerCase().includes(audioSearchTerm.toLowerCase())
                );
            }
            
            // Apply sorting
            applySorting(filteredAudio, audioSortOption);
            setFilteredMediaList(prev => ({ ...prev, audio: filteredAudio }));
        }
    }, [mediaList.audio, audioSortOption, audioSearchTerm]);

    useEffect(() => {
        // Apply sorting to video files
        if (mediaList.video.length > 0) {
            const sortedVideo = [...mediaList.video];
            applySorting(sortedVideo, videoSortOption);
            setFilteredMediaList(prev => ({ ...prev, video: sortedVideo }));
        }
    }, [mediaList.video, videoSortOption]);

    const applySorting = (mediaArray, sortOption) => {
        switch (sortOption) {
            case SORT_OPTIONS.NEWEST:
                mediaArray.sort((a, b) => {
                    const dateA = new Date(a.lastModified);
                    const dateB = new Date(b.lastModified);
                    return dateB - dateA;
                });
                break;
            case SORT_OPTIONS.OLDEST:
                mediaArray.sort((a, b) => {
                    const dateA = new Date(a.lastModified);
                    const dateB = new Date(b.lastModified);
                    return dateA - dateB;
                });
                break;
            case SORT_OPTIONS.A_Z:
                mediaArray.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
                break;
            case SORT_OPTIONS.Z_A:
                mediaArray.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                break;
            default:
                // Default to newest
                mediaArray.sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
        }
    };

    const handleSortChange = (mediaType, sortOption) => {
        if (mediaType === 'audio') {
            setAudioSortOption(sortOption);
        } else if (mediaType === 'video') {
            setVideoSortOption(sortOption);
        }
    };

    const fetchMediaList = async () => {
        try {
            const [audioResponse, videoResponse] = await Promise.all([
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/media?folder=${FOLDER_PATHS.AUDIO}`,
                    { withCredentials: true }
                ),
                axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/media?folder=${FOLDER_PATHS.VIDEO}`,
                    { withCredentials: true }
                )
            ]);

            // Create a map to handle duplicates by file name
            const audioFilesMap = new Map();

            // Process primary audio files
            audioResponse.data.blobs.forEach(media => {
                if (media.type.startsWith('audio/') || 
                    media.name.toLowerCase().endsWith('.mp3') ||
                    media.name.toLowerCase().endsWith('.mpeg')) {
                    audioFilesMap.set(media.name, media);
                }
            });

            // Process secondary audio files (blobs2) if they exist
            if (audioResponse.data.blobs2) {
                audioResponse.data.blobs2.forEach(media => {
                    if (media.type.startsWith('audio/') || 
                        media.name.toLowerCase().endsWith('.mp3') || 
                        media.name.toLowerCase().endsWith('.mpeg')) {
                        // Only update if the file is newer or doesn't exist
                        const existingFile = audioFilesMap.get(media.name);
                        if (!existingFile || new Date(media.lastModified) > new Date(existingFile.lastModified)) {
                            audioFilesMap.set(media.name, media);
                        }
                    }
                });
            }

            // Convert map back to array
            const audioFiles = Array.from(audioFilesMap.values());

            const videoFiles = videoResponse.data.blobs.filter(media => 
                media.type.startsWith('video/') || media.name.endsWith('.mp4')
            );

            // Sort the arrays before setting state
            const sortedAudio = [...audioFiles];
            const sortedVideo = [...videoFiles];
            
            applySorting(sortedAudio, audioSortOption);
            applySorting(sortedVideo, videoSortOption);

            setMediaList({
                audio: audioFiles,
                video: videoFiles
            });
            
            setFilteredMediaList({
                audio: sortedAudio,
                video: sortedVideo
            });
        } catch (error) {
            setError('Failed to fetch media list');
            console.error('Error fetching media:', error);
        }
    };

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setFiles(Array.from(e.dataTransfer.files));
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleUpload = async () => {
        if (files.length === 0) {
            setError('Please select files to upload');
            return;
        }

        setUploading(true);
        setError('');
        setMessage('');

        const formData = new FormData();
        files.forEach(file => {
            formData.append('media', file);
        });

        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/media/upload`,
                formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setMessage('Files uploaded successfully');
            setFiles([]);
            await fetchMediaList();
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to upload files');
            console.error('Upload error:', error);
        } finally {
            setUploading(false);
        }
    };

    const toggleSection = (section) => {
        if (section === 'audio') {
            setAudioOpen(!audioOpen);
        } else if (section === 'video') {
            setVideoOpen(!videoOpen);
        }
    };

    const handleSearchChange = (e) => {
        setAudioSearchTerm(e.target.value);
    };

    const renderControls = (mediaType, currentSortOption) => {
        if (mediaType === 'audio') {
            return (
                <div className="controls-container">
                    <div className="search-controls">
                        <input
                            type="text"
                            placeholder="Search audio files..."
                            value={audioSearchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                    </div>
                    <div className="sorting-controls">
                        <label>Sort by:</label>
                        <select 
                            value={currentSortOption}
                            onChange={(e) => handleSortChange(mediaType, e.target.value)}
                            className="sort-select"
                        >
                            <option value={SORT_OPTIONS.NEWEST}>Newest First</option>
                            <option value={SORT_OPTIONS.OLDEST}>Oldest First</option>
                            <option value={SORT_OPTIONS.A_Z}>A-Z</option>
                            <option value={SORT_OPTIONS.Z_A}>Z-A</option>
                        </select>
                    </div>
                </div>
            );
        }
        return renderSortingControls(mediaType, currentSortOption);
    };

    const renderSortingControls = (mediaType, currentSortOption) => {
        return (
            <div className="sorting-controls">
                <label>Sort by:</label>
                <select 
                    value={currentSortOption}
                    onChange={(e) => handleSortChange(mediaType, e.target.value)}
                    className="sort-select"
                >
                    <option value={SORT_OPTIONS.NEWEST}>Newest First</option>
                    <option value={SORT_OPTIONS.OLDEST}>Oldest First</option>
                    <option value={SORT_OPTIONS.A_Z}>A-Z</option>
                    <option value={SORT_OPTIONS.Z_A}>Z-A</option>
                </select>
            </div>
        );
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Media Library</h1>
            </div>

            {error && <div className="error-message">{error}</div>}
            {message && <div className="success-message">{message}</div>}

            <div className="upload-section">
                <div 
                    className="upload-box"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onClick={() => document.getElementById('file-input').click()}
                    style={{ cursor: 'pointer' }}
                >
                    <i className="fas fa-cloud-upload-alt"></i>
                    <p>Drag and drop filer her eller klikk for å velge</p>
                    <input 
                        id="file-input"
                        type="file" 
                        multiple 
                        onChange={handleFileChange}
                        className="file-input"
                        accept="image/*,video/mp4,audio/mpeg"
                        style={{ display: 'none' }}
                    />
                    {files.length > 0 && (
                        <div className="selected-files" onClick={e => e.stopPropagation()}>
                            <h3>Valgte filer:</h3>
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                            <button 
                                onClick={handleUpload}
                                disabled={uploading}
                                className="media-upload-button"
                            >
                                {uploading ? 'Laster opp...' : 'Last opp filer'}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="media-gallery">
                <div className="media-section">
                    <div className="section-header-container">
                        <div 
                            className="section-header" 
                            onClick={() => toggleSection('audio')}
                        >
                            <h2>Audio Library ({filteredMediaList.audio.length})</h2>
                            <i className={`fas fa-chevron-${audioOpen ? 'up' : 'down'}`}></i>
                        </div>
                    </div>
                    {audioOpen && renderControls('audio', audioSortOption)}
                    <div className={`section-content ${audioOpen ? 'open' : ''}`}>
                        {filteredMediaList.audio.length === 0 ? (
                            <p>{audioSearchTerm ? 'No matching audio files found' : 'No audio files uploaded yet'}</p>
                        ) : (
                            <div className="media-grid">
                                {filteredMediaList.audio.map((media) => (
                                    <div key={media.id} className="media-item audio-item">
                                        <div className="audio-container">
                                            <i className="fas fa-music"></i>
                                            <audio controls>
                                                <source 
                                                    src={media.url} 
                                                    type="audio/mpeg"
                                                    onError={(e) => console.error(`Error loading audio ${media.name}: ${media.url}`, e)}
                                                />
                                                Your browser does not support the audio element.
                                            </audio>
                                            <div className="audio-info">
                                                <p className="media-name">{media.name}</p>
                                                <p className="media-date">
                                                    {new Date(media.lastModified).toLocaleDateString()}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="media-section">
                    <div className="section-header-container">
                        <div 
                            className="section-header" 
                            onClick={() => toggleSection('video')}
                        >
                            <h2>Video Library ({mediaList.video.length})</h2>
                            <i className={`fas fa-chevron-${videoOpen ? 'up' : 'down'}`}></i>
                        </div>
                    </div>
                    {videoOpen && renderSortingControls('video', videoSortOption)}
                    <div className={`section-content ${videoOpen ? 'open' : ''}`}>
                        {filteredMediaList.video.length === 0 ? (
                            <p>No video files uploaded yet</p>
                        ) : (
                            <div className="media-grid">
                                {filteredMediaList.video.map((media) => (
                                    <div key={media.id} className="media-item video-item">
                                        <video controls>
                                            <source src={media.url} type={media.type} />
                                        </video>
                                        <div className="video-info">
                                            <p className="media-name">{media.name}</p>
                                            <p className="media-date">
                                                {new Date(media.lastModified).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <style jsx>{`
                .controls-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 0;
                    border-bottom: 1px solid #eee;
                    margin-bottom: 15px;
                    gap: 20px;
                }

                .search-controls {
                    flex: 1;
                }

                .search-input {
                    width: 100%;
                    padding: 8px 12px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 14px;
                }

                .search-input:focus {
                    outline: none;
                    border-color: #007bff;
                    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
                }

                .sorting-controls {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    min-width: 200px;
                }
                
                .sort-select {
                    padding: 8px 12px;
                    border-radius: 4px;
                    border: 1px solid #ddd;
                    background-color: white;
                    cursor: pointer;
                    min-width: 140px;
                }

                .sort-select:focus {
                    outline: none;
                    border-color: #007bff;
                    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
                }
                
                .section-header-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #eee;
                    margin-bottom: 15px;
                }
                
                .media-date {
                    font-size: 0.8rem;
                    color: #777;
                    margin-top: 3px;
                }
                
                .audio-info, .video-info {
                    display: flex;
                    flex-direction: column;
                    margin-top: 5px;
                }
                
                .media-name {
                    font-weight: 500;
                    margin-bottom: 3px;
                }

                .upload-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: var(--spacing-xl);
                    border: 2px dashed var(--text-light);
                    border-radius: var(--border-radius-lg);
                    background-color: transparent;
                    transition: all 0.2s ease;
                    margin: var(--spacing-lg) 0;
                }

                .upload-box:hover {
                    border-color: var(--primary-color);
                    background-color: rgba(0, 123, 255, 0.02);
                }

                .upload-box i {
                    font-size: 3rem;
                    color: var(--primary-color);
                    margin-bottom: var(--spacing-md);
                    opacity: 0.8;
                }

                .upload-box p {
                    margin: 0;
                    color: var(--text-dark);
                    font-size: 1rem;
                    text-align: center;
                }

                .selected-files {
                    margin-top: var(--spacing-lg);
                    width: 100%;
                    max-width: 400px;
                }

                .selected-files h3 {
                    margin-bottom: var(--spacing-sm);
                    color: var(--text-dark);
                    font-weight: 500;
                }

                .selected-files ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 var(--spacing-md) 0;
                }

                .selected-files li {
                    padding: var(--spacing-sm);
                    background: transparent;
                    border: 1px solid var(--text-light);
                    border-radius: var(--border-radius-sm);
                    margin-bottom: var(--spacing-xs);
                    color: var(--text-dark);
                    font-size: 0.9rem;
                }

                .upload-button {
                    width: 100%;
                    padding: var(--spacing-md) var(--spacing-lg);
                    background-color: transparent;
                    color: var(--primary-color);
                    border: 2px solid var(--primary-color);
                    border-radius: var(--border-radius-md);
                    cursor: pointer;
                    font-weight: 500;
                    transition: all 0.2s ease;
                    font-size: 0.95rem;
                    letter-spacing: 0.3px;
                }

                .upload-button:hover:not(:disabled) {
                    background-color: var(--primary-color);
                    color: white;
                }

                .upload-button:disabled {
                    border-color: var(--text-light);
                    color: var(--text-light);
                    cursor: not-allowed;
                }
            `}</style>
        </div>
    );
} 