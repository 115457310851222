import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function ActivitiesList() {
    const [activities, setActivities] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchActivities();
    }, []);

    const fetchActivities = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
                { withCredentials: true }
            );
            
            // Group activities by ID to handle duplicates with different albums
            const activityMap = {};
            
            response.data.forEach(activity => {
                const id = activity.idactivity;
                
                if (!activityMap[id]) {
                    activityMap[id] = {
                        ...activity,
                        albums: activity.album_name ? [activity.album_name] : []
                    };
                } else if (activity.album_name && !activityMap[id].albums.includes(activity.album_name)) {
                    activityMap[id].albums.push(activity.album_name);
                }
            });
            
            // Convert the map back to an array
            const groupedActivities = Object.values(activityMap);
            
            setActivities(groupedActivities);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Failed to load activities');
            setLoading(false);
        }
    };

    const handleActivityClick = (activityId) => {
        navigate(`/activities/${activityId}`);
    };

    if (loading) return <div className="loading">Loading...</div>;

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>All Activities</h1>
            </div>

            {error && <div className="error-message">{error}</div>}

            <div className="list-container">
                {activities.length > 0 ? (
                    activities.map(activity => (
                        <div 
                            key={activity.idactivity} 
                            className="list-item clickable"
                            onClick={() => handleActivityClick(activity.idactivity)}
                        >
                            <div className="user-info">
                                <h3>{activity.name}</h3>
                                {activity.description && (
                                    <p className="user-email">{activity.description}</p>
                                )}
                                {activity.programName && (
                                    <p className="user-role">Program: {activity.programName}</p>
                                )}
                                {activity.businessName && (
                                    <p className="user-business">Business: {activity.businessName}</p>
                                )}
                                {activity.albums && activity.albums.length > 0 && (
                                    <div className="activity-albums">
                                        <p className="album-label">
                                            <i className="fas fa-layer-group"></i> 
                                            {activity.albums.length === 1 ? 'Album:' : 'Albums:'}
                                        </p>
                                        <div className="album-tags">
                                            {activity.albums.map((albumName, index) => (
                                                <span key={index} className="album-tag">
                                                    {albumName}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-items-message">No activities found</p>
                )}
            </div>
            
            <style jsx>{`
                .activity-albums {
                    margin-top: 8px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 8px;
                }
                
                .album-label {
                    font-size: 0.9rem;
                    color: #666;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    margin: 0;
                }
                
                .album-tags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 6px;
                }
                
                .album-tag {
                    background-color: #e9f5ff;
                    color: #0066cc;
                    padding: 3px 8px;
                    border-radius: 4px;
                    font-size: 0.85rem;
                    border: 1px solid #cce4ff;
                }
            `}</style>
        </div>
    );
} 