import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../styles/components/forms.css";

export default function IndividualAlbum() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [album, setAlbum] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAlbum();
  }, [id]);

  const fetchAlbum = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/albums/${id}`,
        { withCredentials: true }
      );
      console.log(response.data, "response data");
      setAlbum(response.data.album);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching album:", error);
      setError("Failed to load album");
      setLoading(false);
    }
  };

  const getActivityCount = (activitiesString) => {
    if (!activitiesString) return 0;
    try {
      const jsonString = `[${activitiesString}]`;
      const activities = JSON.parse(jsonString);
      return activities.length;
    } catch (error) {
      console.error('Error parsing activities:', error);
      return 0;
    }
  };

  const getActivities = (activitiesString) => {
    if (!activitiesString) return [];
    try {
      const jsonString = `[${activitiesString}]`;
      const activities = JSON.parse(jsonString);
      return activities;
    } catch (error) {
      console.error("Error parsing activities:", error);
      return [];
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;
  if (!album) return <div>Album not found</div>;

  return (
    <div className="page-container">
      <div className="page-header-stack">
        <Link to="/albums" className="back-button">
          <i className="fas fa-arrow-left"></i> Back to Albums
        </Link>
        
        <div className="album-content">
          <div className="album-header">
            <h1>{album.name}</h1>
            <div className="album-status">
              {album.isPublic ? (
                <span className="status-badge published">
                  <i className="fas fa-check-circle"></i> Published
                </span>
              ) : (
                <span className="status-badge draft">
                  <i className="fas fa-clock"></i> Draft
                </span>
              )}
            </div>
          </div>

          {album.shortDescription && (
            <div className="album-short-description">
              <p>{album.shortDescription}</p>
            </div>
          )}

          {album.description && (
            <div className="album-description">
              <p>{album.description}</p>
            </div>
          )}

          <div className="activities-section">
            <div className="section-header">
              <h2>Activities in this Album</h2>
              <span className="activity-count">
                {album.activities?.length || 0} activities
              </span>
            </div>

            <div className="activities-grid">
              {album.activities?.map((activity) => (
                <div key={activity.id} className="activity-card">
                  <div className="activity-card-content">
                    <h3>{activity.name}</h3>
                    {activity.shortDescription && (
                      <p className="activity-description">{activity.shortDescription}</p>
                    )}
                    <div className="activity-details">
                      {activity.level_name && (
                        <span className="activity-level">
                          <i className="fas fa-chart-line"></i> {activity.level_name}
                        </span>
                      )}
                      {activity.stepsName && (
                        <span className="activity-step">
                          <i className="fas fa-shoe-prints"></i> {activity.stepsName}
                        </span>
                      )}
                      {activity.minutes && (
                        <span className="activity-duration">
                          <i className="fas fa-clock"></i> {activity.minutes} minutes
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="activity-card-actions">
                    <button 
                      className="view-activity-button"
                      onClick={() => navigate(`/activities/${activity.id}`)}
                    >
                      <i className="fas fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .album-short-description {
          margin: 1rem 0;
          padding: 1rem;
          background: #f8f9fa;
          border-radius: 8px;
          font-weight: 500;
          color: #495057;
          font-style: italic;
        }

        .album-description {
          margin: 1rem 0;
          color: #666;
        }

        .album-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid #eee;
        }

        .activity-details {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 8px;
        }

        .activity-details span {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 4px 8px;
          background: #f8f9fa;
          border-radius: 4px;
          font-size: 0.9em;
          color: #666;
        }

        .activity-details i {
          color: #007bff;
        }
      `}</style>
    </div>
  );
}
