import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function NewAlbum() {
    const [formData, setFormData] = useState({
        name: '',
        shortDescription: '',
        description: '',
        isPublic: false
    });
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [activities, setActivities] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const [albums, setAlbums] = useState([]);
    const [editingAlbum, setEditingAlbum] = useState(null);
    const [editFormData, setEditFormData] = useState({
        name: '',
        shortDescription: '',
        description: '',
        isPublic: false,
        activities: []
    });
    const navigate = useNavigate();
    useEffect(() => {
        fetchActivities();
        fetchAlbums();
    }, []);

    const fetchActivities = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/activities`,
                { withCredentials: true }
            );
            setActivities(response.data);
        } catch (error) {
            console.error('Error fetching activities:', error);
            setError('Failed to load activities');
        }
    };

    const fetchAlbums = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/albums`,
                { withCredentials: true }
            );
            const parsedAlbums = response.data.albums.map(album => ({
                ...album,
                activities: parseActivities(album.activities)
            }));
            setAlbums(parsedAlbums);
        } catch (error) {
            console.error('Error fetching albums:', error);
            setError('Failed to load albums');
        }
    };

    const parseActivities = (activitiesStr) => {
        if (!activitiesStr) return [];
        try {
            const jsonStr = `[${activitiesStr}]`;
            return JSON.parse(jsonStr);
        } catch (error) {
            console.error('Error parsing activities:', error);
            return [];
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleEditChange = (e) => {
        const { name, value, type, checked } = e.target;
        setEditFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleActivitySearch = (activity, isEditing = false) => {
        if (isEditing) {
            if (!editFormData.activities.find(a => a.id === activity.idactivity)) {
                setEditFormData(prev => ({
                    ...prev,
                    activities: [...prev.activities, {
                        id: activity.idactivity,
                        name: activity.name
                    }]
                }));
            }
        } else {
            if (!selectedActivities.find(a => a.id === activity.idactivity)) {
                setSelectedActivities(prev => [...prev, {
                    id: activity.idactivity,
                    name: activity.name
                }]);
            }
        }
        setSearchTerm('');
    };

    const removeActivity = (activityId, isEditing = false) => {
        if (isEditing) {
            setEditFormData(prev => ({
                ...prev,
                activities: prev.activities.filter(activity => activity.id !== activityId)
            }));
        } else {
            setSelectedActivities(prev => 
                prev.filter(activity => activity.id !== activityId)
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/albums`,
                {
                    ...formData,
                    activities: selectedActivities.map(activity => activity.id)
                },
                { withCredentials: true }
            );

            if (response.status === 201) {
                setMsg('Album created successfully!');
                setFormData({ name: '', shortDescription: '', description: '', isPublic: false });
                setSelectedActivities([]);
                fetchAlbums();
                setTimeout(() => setMsg(''), 3000);
            }
        } catch (error) {
            console.error('Error creating album:', error);
            setError(error.response?.data?.message || 'Failed to create album');
            setTimeout(() => setError(''), 3000);
        }
    };

    const startEditing = (album) => {
        setEditingAlbum(album.idalbums);
        setEditFormData({
            name: album.name,
            shortDescription: album.shortDescription || '',
            description: album.description || '',
            isPublic: album.isPublic || false,
            activities: album.activities || []
        });
    };

    const cancelEditing = () => {
        setEditingAlbum(null);
        setEditFormData({
            name: '',
            shortDescription: '',
            description: '',
            isPublic: false,
            activities: []
        });
    };

    const saveAlbum = async (albumId) => {
        try {
            const formattedActivities = editFormData.activities
                .map(activity => ({
                    id: activity.id,
                    name: activity.name
                }))
                // .map(activity => JSON.stringify(activity))
                // .join(',');

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/albums/${albumId}`,
                {
                    name: editFormData.name,
                    shortDescription: editFormData.shortDescription,
                    description: editFormData.description,
                    isPublic: editFormData.isPublic,
                    activities: formattedActivities
                },
                { withCredentials: true }
            );

            if (response.status === 200) {
                setMsg('Album updated successfully!');
                setEditingAlbum(null);
                fetchAlbums();
                setTimeout(() => setMsg(''), 3000);
            }
        } catch (error) {
            console.error('Error updating album:', error);
            setError(error.response?.data?.message || 'Failed to update album');
            setTimeout(() => setError(''), 3000);
        }
    };

    const deleteAlbum = async (albumId) => {
        if (window.confirm('Are you sure you want to delete this album?')) {
            try {
                const response = await axios.delete(
                    `${process.env.REACT_APP_BACKEND_URL}/api/albums/${albumId}`,
                    { withCredentials: true }
                );

                if (response.status === 200) {
                    setMsg('Album deleted successfully!');
                    fetchAlbums();
                    setTimeout(() => setMsg(''), 3000);
                }
            } catch (error) {
                console.error('Error deleting album:', error);
                setError(error.response?.data?.message || 'Failed to delete album');
                setTimeout(() => setError(''), 3000);
            }
        }
    };

    return (
        <div className="page-container">
            <div className="page-header-stack">
                <Link to="/dashboard" className="back-button">
                    <i className="fas fa-arrow-left"></i> Back to Dashboard
                </Link>
                <h1>Create New Album</h1>
            </div>

            {msg && <div className="success-message">{msg}</div>}
            {error && <div className="error-message">{error}</div>}

            <form className="form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Album Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="shortDescription">Short Description</label>
                    <input
                        type="text"
                        id="shortDescription"
                        name="shortDescription"
                        value={formData.shortDescription}
                        onChange={handleChange}
                        maxLength="255"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        rows="4"
                    />
                </div>

                <div className="form-group toggle-group">
                    <label className="toggle-label">
                        <span className="toggle-text">Published Album</span>
                        <i className="fas fa-info-circle tooltip-icon" 
                           data-tooltip="Public albums are visible to all users. Private albums are only visible to admins.">
                        </i>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                name="isPublic"
                                checked={formData.isPublic}
                                onChange={handleChange}
                            />
                            <span className="toggle-slider"></span>
                        </div>
                     
                    </label>
                </div>

                <div className="form-group">
                    <label>Search Activities</label>
                    <div className="search-container">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search activities..."
                            className="search-input"
                        />
                        {searchTerm && (
                            <div className="search-results">
                                {activities
                                    .filter(activity => 
                                        activity.name.toLowerCase().includes(searchTerm.toLowerCase())
                                    )
                                    .map(activity => (
                                        <div
                                            key={activity.idactivity}
                                            className="search-result-item"
                                            onClick={() => handleActivitySearch(activity)}
                                        >
                                            {activity.name}
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </div>
                </div>

                <div className="selected-activities">
                    {selectedActivities.map((activity) => (
                        <div key={activity.id} className="selected-activity-item">
                            <span>{activity.name}</span>
                            <button
                                type="button"
                                onClick={() => removeActivity(activity.id)}
                                className="remove-button"
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                    ))}
                </div>

                <div className="form-actions">
                    <button type="submit" className="submit-button">
                        Create Album
                    </button>
                    <button 
                        className="cancel-button"
                        onClick={() => navigate('/dashboard')}
                    >
                        Cancel
                    </button>
       
    
                </div>
            </form>

            <div className="programs-list">
                <div className="programs-header">
                    <h2>Existing Albums</h2>
                </div>

                <div className="list-container">
                    {albums.map(album => (
                        <div key={album.idalbums} className="program-card">
                            {editingAlbum === album.idalbums ? (
                                <div className="edit-form">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            value={editFormData.name}
                                            onChange={handleEditChange}
                                            className="edit-input"
                                            placeholder="Album name"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="shortDescription"
                                            value={editFormData.shortDescription}
                                            onChange={handleEditChange}
                                            className="edit-input"
                                            placeholder="Short description"
                                            maxLength="255"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            name="description"
                                            value={editFormData.description}
                                            onChange={handleEditChange}
                                            className="edit-textarea"
                                            placeholder="Description"
                                            rows="3"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className="search-container">
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                placeholder="Search activities..."
                                                className="search-input"
                                            />
                                            {searchTerm && (
                                                <div className="search-results">
                                                    {activities
                                                        .filter(activity => 
                                                            activity.name.toLowerCase().includes(searchTerm.toLowerCase())
                                                        )
                                                        .map(activity => (
                                                            <div
                                                                key={activity.idactivity}
                                                                className="search-result-item"
                                                                onClick={() => handleActivitySearch(activity, true)}
                                                            >
                                                                {activity.name}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="selected-activities">
                                        {editFormData.activities.map((activity) => (
                                            <div key={activity.id} className="selected-activity-item">
                                                <span>{activity.name}</span>
                                                <button
                                                    type="button"
                                                    onClick={() => removeActivity(activity.id, true)}
                                                    className="remove-button"
                                                >
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="form-group toggle-group">
                                        <label className="toggle-label">
                                            <span className="toggle-text">Published Album</span>
                                            <div className="toggle-switch">
                                                <input
                                                    type="checkbox"
                                                    name="isPublic"
                                                    checked={editFormData.isPublic}
                                                    onChange={handleEditChange}
                                                />
                                                <span className="toggle-slider"></span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className="edit-actions">
                                        <button 
                                            onClick={() => saveAlbum(album.idalbums)}
                                            className="save-button"
                                        >
                                            <i className="fas fa-save"></i> Save
                                        </button>
                                        <button 
                                            onClick={cancelEditing}
                                            className="cancel-button"
                                        >
                                            <i className="fas fa-times"></i> Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="program-header">
                                        <h3>{album.name}</h3>
                                        <div className="program-actions">
                                            <button 
                                                onClick={() => startEditing(album)}
                                                className="edit-button"
                                            >
                                                <i className="fas fa-edit"></i>
                                            </button>
                                            <button 
                                                onClick={() => deleteAlbum(album.idalbums)}
                                                className="delete-button"
                                            >
                                                <i className="fas fa-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="program-description">
                                        {album.shortDescription && (
                                            <p className="short-description">{album.shortDescription}</p>
                                        )}
                                        <p>{album.description}</p>
                                        <div className="activity-tags">
                                            <span className="selected-program-tag">
                                                <i className="fas fa-image"></i> 
                                                {album.activities?.length || 0} activities
                                            </span>
                                            {album.activities?.map((activity) => (
                                                <span key={activity.id} className="selected-program-tag">
                                                    <i className="fas fa-running"></i> {activity.name}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}